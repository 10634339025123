import React from "react"
import { Helmet } from "react-helmet"
import LayoutMain from "../components/layouts/LayoutMain"
import { Link } from "gatsby"

import "../styles/privacy.scss"


const PrivacyPage = (props) => (

      <LayoutMain>
        <Helmet>
          <title>Structured Marketing Privacy Policy</title>
          <meta
            name="description"
            content="Structured Marketing Privacy Policy"
          />
          <bodyAttributes
            className="theme1"
          />
        </Helmet>


        <div className="container-privacy padding-standard bgColorLight">
          <div className="privacyPolicy">
            <h1>Structured Marketing Privacy Policy</h1>

            <p>Last Updated: May 16, 2023</p>

            <p>Structured Marketing knows that you care how information about you is used and shared, and we appreciate your trust that we will do so carefully and sensibly. This notice describes our privacy policy. By visiting a Structured Marketing property, you are accepting the practices described in this Privacy Notice.</p>



            <h2>Personal Information Gathered</h2>
            <p>The information we learn from customers helps us personalize and continually improve your Structured Marketing experience. Here are the types of information we gather.</p>

            <h3>Information You Give Us</h3>
            <p>We receive and store any information you enter on our website or give us in any other way. You can choose not to provide certain information, but then you might not be able to take advantage of many of our features. We use the information that you provide for such purposes as responding to your requests, customizing your experience, improving our products & services, and communicating with you.</p>

            <h3>Automatic Information</h3>
            <p>We receive and store certain types of information whenever you interact with us. For example, like many websites, we use "cookies," and we obtain certain types of information when your web browser accesses our site or advertisements and other content served by or on behalf of Structured Marketing on other websites.</p>
            <p>Structured Marketing apps may collect and transmit data from any Structured Marketing device to Structured Marketing servers.  Any encrypted information collected in this process is passed to the Structured Marketing services without decrypting the information.</p>

            <h3>Location</h3>
            <p>When you download or use apps created by Structured Marketing, we may receive information about your location and your mobile device, including a unique identifier for your device. We may use this information to provide you with location-based services, proximity to other Structured Marketing devices, advertising, search results, and other personalized content.</p>

            <h3>Electronic Communications</h3>
            <p>To help us make electronic communication more useful and interesting, we often receive a confirmation when you open electronic communication from Structured Marketing if your computer supports such capabilities. If you do not want to receive electornic communication or other mail from us, please contact us.</p>

            <h3>Information from Other Sources</h3>
            <p>We might receive information about you from other sources and add it to our account information.</p>



            <h2>Cookies</h2>
            <p>Cookies are unique identifiers that we transfer to your device to enable our systems to recognize your device and to provide features such as personalized advertisements on other Web sites, analytics, and storage of information between visits.</p>

            <p>The Help feature on most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether.</p>

            <p>You can change cookie settings for Structured Marketing.com at <Link to="/cookies">https://www.Structured Marketing.com/cookies</Link>.</p>

            <p>On Structured Marketing apps, you can opt-out of analytics tracking through applicable device settings, such as the device advertising settings for mobile apps.</p>

            <h2>Sharing Information Structured Marketing Receives</h2>
            <p>Information about our customers is an important part of our business, and we are not in the business of selling it to others. We share customer information only as described below and with subsidiaries Structured Marketing controls that either are subject to this Privacy Notice or follow practices at least as protective as those described in this Privacy Notice.</p>

            <h3>Third-Party Service Providers</h3>
            <p>We employ other companies and individuals to perform functions on our behalf. Examples include sending postal mail and e-mail, removing repetitive information from customer lists, analyzing data, providing marketing assistance, providing search results and links (including paid listings and links). They have access to personal information needed to perform their functions, but may not use it for other purposes.</p>

            <h3>Third-Party Integrations</h3>
            <p>When you share information with a 3rd party integration to perform functions beyond what Structured Marketing provides, Structured Marketing is not responsible for the data policy of the 3rd party. Examples are remote patient monitoring services independent of Structured Marketing.</p>

            <h3>Promotional Offers</h3>
            <p>Sometimes we send offers to selected groups of Structured Marketing customers on behalf of other businesses. If you do not want to receive such offers, please contact us.</p>

            <h3>Business Transfers</h3>
            <p>As we continue to develop our business, we might sell or buy subsidiaries, or business units. In such transactions, customer information generally is one of the transferred business assets. Also, in the event that Structured Marketing or substantially all of its assets are acquired, customer information will be one of the transferred assets.</p>

            <h3>Protection of Structured Marketing and Others</h3>
            <p>We release account and other personal information when we believe release is appropriate to comply with the law; enforce or apply our Conditions of Use and other agreements; or protect the rights, property, or safety of Structured Marketing, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction. Obviously, however, this does not include selling, renting, sharing, or otherwise disclosing personally identifiable information from customers for commercial purposes in violation of the commitments set forth in this Privacy Notice.</p>



            <h2>Security</h2>
            <p>We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you input.</p>


            <h2>Children</h2>
            <p>If you are under 18, you may use Structured Marketing only with the involvement of a parent or guardian. We do not knowingly collect personal information from children under the age of 13 without the consent of the child's parent or guardian.</p>


            <h2>Conditions of Use, Notices, and Revisions</h2>
            <p>If you choose to visit Structured Marketing, your visit and any dispute over privacy is subject to this Notice and our Conditions of Use, including limitations on damages, resolution of disputes, and application of the law of the state of California. If you have any concern about privacy at Structured Marketing, please contact us with a thorough description, and we will try to resolve it. As our business changes, our Privacy Notice and the Conditions of Use will change also. You should check our website frequently to see recent changes. Unless stated otherwise, our current Privacy Notice applies to all information that we have about you and your account.</p>


            <h2>Contact Information</h2>
            <p>Structured Marketing LLC<br/>
            4304 Endcliffe Drive<br/>
            Austin, TX 78731</p>

            <p>Email Address: hello@structured-marketing.com</p>


          </div>


        </div>


      </LayoutMain>
    )

export default PrivacyPage



